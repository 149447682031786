import axios from "@/axios.js"
import _ from 'lodash'
import helper from "./priceRuleHelper"

export default {
  isRegistered: false,
  namespaced: true,
  state: {
    priceRules: [],
    formData:{},
    deleteDialog: { show: true, priceRuleId: 0, affectedUsers: 0 }
  },
  mutations: {
    SET_PRICE_RULE_LIST(state, priceRules) {     
      state.priceRules = priceRules;
    },
    SET_FORM_DATA(state, formData) {
      state.formData = formData;
    },
    ADD_PRICE_RULE(state, priceRule) {
      state.priceRules.push(priceRule)
    },
    UPDATE_PRICE_RULE(state, priceRule) {
      const priceRuleIndex = _.findIndex(state.priceRules, (item) => item.id === priceRule.priceRuleId)
      state.priceRules[priceRuleIndex] = priceRule
    },
    REMOVE_PRICE_RULE(state, priceRuleId) {
      const priceRuleIndex = _.findIndex(state.priceRules, (item) => item.id === priceRuleId)
      state.priceRules.splice(priceRuleIndex, 1)
    },
    SET_DELETE_DIALOG(state, deleteDialog) {
      state.deleteDialog = deleteDialog
    }
  },
  actions: {
    fetchFormData({ commit },payload) {      
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.formDataAPI,payload);
        axios.get(url)
          .then(response => {
            commit('SET_FORM_DATA', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    fetchPriceRules({ commit }, payload) {
      // disabled to use dummy data
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.listAPI, payload);
        axios.get(url)
          .then(response => {
            commit('SET_PRICE_RULE_LIST', response.data);
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
      /**
       * the response data  structure 
       * [{
            "ruleId": 0,
            "ruleAppId": 0,
            "ruleName": null,
            "rulePriority": 0,
            "ruleIsActive": false,
            "ruleCreated": "0001-01-01T00:00:00"
          }]
       */
    },
    addPriceRule({ commit }, payload) {
      // disabled to use dummy data
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.addAPI, payload.applicationId);
        axios.post(url, { ruleData: JSON.stringify(payload.data) })
          .then(response => {
            /**
             * Eventually, fetchPriceRules will be executed upon successfull add request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
            // commit('ADD_PRICE_RULE', {
            //   ruleId: response.data.ruleId,
            //   ruleAppId: payload.data.applicationId,
            //   ruleName: payload.data.name,
            //   rulePriority: payload.data.priority,
            //   ruleIsActive: payload.data.isActive,
            //   ruleCreated: response.data.ruleCreated,
            // });
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
    updatePriceRule({ commit }, payload) {
      return new Promise((resolve, reject) => {
      const url = helper.StringFormat(helper.priceRuleAPIs.updateAPI, payload.applicationId, payload.priceRuleId);
      axios.put(url, { ruleData: JSON.stringify(payload.data) })
        .then(response => {
          /**
             * Eventually, fetchPriceRules will be executed upon successfull update request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
          // commit('UPDATE_PRICE_RULE', {
          //   ruleId: response.data.ruleId,
          //   ruleAppId: payload.data.applicationId,
          //   ruleName: payload.data.name,
          //   rulePriority: payload.data.priority,
          //   ruleIsActive: payload.data.isActive,
          //   ruleCreated: response.data.ruleCreated,
          // });
          resolve(response);
        }).catch(error => {
          reject(error);
        });
      })

    },
    removePriceRule({ commit }, payload) {
      return new Promise((resolve, reject) => {
        const url = helper.StringFormat(helper.priceRuleAPIs.deleteAPI, payload.applicationId, payload.priceRuleId);
        axios.delete(url)
          .then(response => {
            /**
             * Eventually, fetchPriceRules will be executed upon successfull delete request
             * So, commit here may not be needed
             * uncomment the following lines if needed
             */
            //commit('REMOVE_PRICE_RULE', payload.priceRuleId)
            resolve(response);
          }).catch(error => {
            reject(error);
          });
      })
    },
  }
}

//For back up purpose
// actions: {
//   fetchPriceRules ({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       axios.get(`api/pricerules/${payload}/rules`)
//         .then(response => {
//           commit('SET_PRICE_RULE_LIST', response.data);
//           resolve(response);
//         }).catch(error => {
//         reject(error);
//       });
//     })
//   },
//   addPriceRule ({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       axios.post(`api/pricerules/apps/${payload.applicationId}/rules`, payload.data)
//         .then(response => {
//           commit('ADD_PRICE_RULE', {
//             priceRuleId: response.data,
//             priceRuleName: payload.data.priceRuleName,
//             priceRuleDescription: payload.data.priceRuleDescription,
//             priceRuleType: 'Custom'
//           });
//           resolve(response);
//         }).catch(error => {
//         reject(error);
//       });
//     })
//   },
//   updatePriceRule ({ commit }, payload) {
//     return new Promise((resolve, reject) => {
//       axios.put(`api/pricerules/apps/${payload.applicationId}/pricerules/${payload.priceRuleId}`, payload.data)
//         .then(response => {
//           commit('UPDATE_PRICE_RULE', {
//             priceRuleId: payload.priceRuleId,
//             priceRuleName: payload.data.priceRuleName,
//             priceRuleDescription: payload.data.priceRuleDescription,
//             priceRuleType: 'Custom'
//           });
//           resolve(response);
//         }).catch(error => {
//         reject(error);
//       });
//     })
//   },
//   removePriceRule ({ commit }, payload) {
//     let queryString = payload.replacementPriceRuleId != null
//       ? `?replacementPriceRuleId=${payload.replacementPriceRuleId}`
//       : ''

//     return new Promise((resolve, reject) => {
//       axios.delete(`api/pricerules/apps/${payload.applicationId}/pricerules/${payload.priceRuleId}${queryString}`)
//         .then(response => {
//           commit('REMOVE_PRICE_RULE', payload.priceRuleId)
//           resolve(response);
//         }).catch(error => {
//         reject(error);
//       });
//     })
//   },
// }